<template>
  <div class="guide-vr">
    <div class="wrapper">
      <guide title="VR STORE" image="guide_2" class="vr">
        <p>톰그레이하운드 스토어를 VR로 자유롭게 둘러보고,</p>
        <p>2021 WINTER 컬렉션도 쇼핑해보세요!</p>
      </guide>
      <div class="button" @click="goNext()">
        <span>확인</span>
      </div>
    </div>
    <div class="bottom">
      <img
        class="tom-greyhound"
        src="../assets/re_main_logo_tomgreyhound.png"
        alt="Tom Greyhound"
      />
    </div>
  </div>
</template>

<script>
import Guide from "@/components/Guide.vue";

export default {
  components: {
    Guide,
  },
  methods: {
    goNext() {
      this.$router.push("/vr-store");
    },
  },
};
</script>

<style lang="scss" scoped>
.guide-vr {
  line-height: 1;
  height: 100%;
  .wrapper {
    width: 274px;
    height: 494px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: calc((100% - 43px) / 2 - (494px / 2));
    .guide {
      .message {
        p {
          line-height: 1.6;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
  }
  .button {
    margin: 0 auto;
    margin-top: 22px;
    width: 243px;
    height: 36px;
    font-family: "Noto Sans KR";
    font-size: 13px;
    line-height: 28px;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 3px;
    background-color: #fff;
    span {
      display: inline;
      vertical-align: middle;
      letter-spacing: -1px;
      margin-left: 3px;
    }
  }
  .bottom {
    position: fixed;
    width: 100%;
    bottom: 24px;
    .tom-greyhound {
      width: 95px;
    }
  }
}
</style>
